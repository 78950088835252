import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { Author } from '../../../../@domain/author/entities/author.interface';
import { AppRoutes } from '../../../../routing/types/app-route';

@Component({
    selector: 'app-author-item',
    templateUrl: './author-item.component.html',
    styleUrls: [ './author-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, ImageDirective ],
})
export class AuthorItemComponent {
  @Input({required: true}) author!: Author;
  @Input() following = false;
    AppRoutes = AppRoutes;
}
