<a class="author" routerLink="/{{ AppRoutes.Authors }}/{{ author.slug}}">
  <img src="../../../../../assets/character.png" alt="author_img" loading="lazy"/>
  <div>
    <p class="name">{{ author.name }}</p>
    <div class="numbers">
      <p class="videos">{{ author.num_lectures }} Videos</p>
      <p class="views">{{ author.views }} Views</p>
    </div>
  </div>
</a>
